<template>
  <div class="body">
    <div class="banner">
      <h2 class="banne-title">橙仕<strong>数智大脑</strong></h2>
      <picture>
        <source
          srcset="https://alihtml.juzhen.cn/juzhen/assets/img/ai/m/brain.jpg"
          media="all and (max-width: 500px)"
        />
        <img class="preview ani"
          src="https://alihtml.juzhen.cn/juzhen/assets/img/ai/brain.jpg"
          width="910" height="715"
          alt="橙仕数智大脑"
        />
      </picture>
    </div>

    <article class="box data">
      <h3>以<strong>数据为驱动</strong></h3>
      <h4>链接"<strong>人</strong>"、"<strong>车</strong>"、"<strong>物</strong>"打造<strong>全域数据链</strong></h4>
      <p class="desc">以车辆数据和驾驶数据为基础，对接城市邮政部门的物流数<span class="space mHide"></span>据，共同打造了车辆大数据中枢【橙仕大脑】，各企业和政府均可参与共建共享的安全城市<span class="space mHide"></span>大脑，最终形成一个可以更好服务城市的大数据平台，同时满足政府各项实惠政策有的放矢。</p>
      <picture>
        <source
          srcset="https://alihtml.juzhen.cn/juzhen/assets/img/ai/m/dataview.png"
          media="all and (max-width: 500px)"
        />
        <img class="preview ani"
          src="https://alihtml.juzhen.cn/juzhen/assets/img/ai/dataview.png"
          width="525" height="795"
          alt="以数据为驱动"
        />
      </picture>
    </article>

    <article class="box map">
      <h3><strong>城市数据化</strong>应用场景</h3>
      <p class="desc">通过人工智能，大数据和车辆追踪技术<span class="mHide">，</span><br class="mShow" />协同物流合作平台，<br class="mHide" />推动城市数据化应用场景</p>
      <div class="mapView">
        <picture>
          <source
            srcset="https://alihtml.juzhen.cn/juzhen/assets/img/ai/m/map.jpg"
            media="all and (max-width: 500px)"
          />
          <img class="preview ani"
            src="https://alihtml.juzhen.cn/juzhen/assets/img/ai/map.jpg"
            width="1920" height="640"
            alt="城市数据化应用场景"
          />
        </picture>
      </div>
    </article>

    <article class="box fiveG">
      <h3><strong>5G</strong>全生命周期管理</h3>
      <h4>让<strong>安全</strong>可以被大数据<strong>预测追踪</strong></h4>
      <p class="desc">通过对零部件不断进行高强度测试，包括环境可靠性测试、电学性能测试、功能测试、EMC测试、材料测试、绿色环保测试及化学法规符合性服务项目。结合大数据AI平台，对这些测试数据和实际发生的数据进行不断机器对比分析学习，通过算法模型建设一套精准的5G远程零部件生命周期管理系统，这套系统可以更好推算车辆当前安全性，以及出行的损耗比，进而预测每次出行，让每次出行的安全性都可以被数据化。</p>
      <img src="https://alihtml.juzhen.cn/juzhen/assets/img/ai/car.png" alt="5G" width="331" height="282" />
    </article>

    <article class="box brain">
      <h3>矩阵定制化<strong>橙仕大脑</strong></h3>
      <h4>不同部门的特殊和诉求<br class="mShow" />定制不同的橙仕大脑应用</h4>
      <img src="https://alihtml.juzhen.cn/juzhen/assets/img/ai/custom.png" alt="5G" width="900" height="317" />
    </article>
  </div>
</template>
<script>
export default {
  name:'Ai'
}
</script>
<style lang="scss" scoped>
.banner {
  padding-top: 260px;
  background-color: #e83e0b;
  
  h2 {
    margin-bottom: 10px;
    font-size: 100px;
    line-height: 116px;
    color: #fff;
    text-align: center;
  }

  img {
    display: block;
    margin: 0 auto;
    width: 910px;
    height: auto;
  }
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  line-height: 30px;

  h3, h4 {
    font-size: 80px;
    line-height: 100px;
    color: #e83e0b;
    text-align: center;
  }

  h4 {
    font-size: 60px;
    line-height: 80px;
  }

  .desc {
    margin-top: 12px;
    max-width: 760px;
    text-indent: 2em;
    text-align: justify;
  }

  img {
    display: block;
    margin: 0 auto;
    height: auto;
  }

  &.data {
    position: relative;
    padding: 104px 0;
    height: 795px;

    h3, h4 {
      position: relative;
      background-color: #fff;
      z-index: 5;
    }

    .desc {
      margin-top: 16px;
      max-width: 960px;

      .space {
        display: inline-block;
        vertical-align: top;
        width: 144px;
        height: 1px;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -99px;
      width: 525px;
      z-index: 1;
    }
  }

  &.map {
    margin-top: 80px;

    .desc {
      margin: 20px auto 80px;
      max-width: 860px;
      text-align: center;
      text-indent: 0;
      font-size: 30px;
      line-height: 45px;
      color: #e83e0b;
    }

    .mapView {
      width: 100%;
      background-color: #19191b;

      img {
        max-width: 100%;
      }
    }
  }

  &.fiveG {
    margin-top: 178px;

    .desc {
      margin-bottom: 40px;
    }
  }

  &.brain {
    padding: 140px 0;

    h4 {
      margin-bottom: 36px;
      font-size: 45px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .banner {
    padding-top: 150px;

    h2 {
      margin-bottom: 66px;
      font-size: 45px;
      line-height: 51px;
    }

    img {
      width: 100%;
    }
  }

  .box {
    font-size: 12px;
    line-height: 18px;

    h3 {
      font-size: 30px;
      line-height: 38px;
    }

    h4 {
      font-size: 20px;
      line-height: 26px;
    }

    .desc {
      margin-top: 12px;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    &.data {
      position: relative;
      padding: 32px 0;
      height: auto;

      h3 {
        padding: 8px 0 0;
        font-size: 40px;
        line-height: 50px;
      }

      h4 {
        padding: 0 0 8px;
        font-size: 20px;
        line-height: 30px;
      }

      .desc {
        margin-top: 204px;
        padding: 0 30px;
      }

      img {
        left: 40.6%;
        margin-left: 0;
        width: 54vw;
        z-index: 1;
      }
    }

    &.map {
      margin-top: 12px;

      .desc {
        margin: 6px auto 36px;
        font-size: 16px;
        line-height: 20px;
      }
    }

    &.fiveG {
      margin-top: 76px;

      .desc {
        margin: 6px 0 26px;
        padding: 0 30px;
      }

      img {
        width: 165px;
      }
    }

    &.brain {
      padding: 42px 30px;

      h4 {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}
</style>